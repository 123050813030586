<template>
  <div id="app">
    <div class="app-main">
      <slot />
    </div>
    <preview-resource
      v-if="ctrl.show"
      :id="ctrl.id"
      :model-value="ctrl.show"
      @close="ctrl.show = false"
    />
    <back-top />
    <suspense-box />
  </div>
</template>

<script setup>
import cookie from '@/utils/cookies'
import { useUserStore } from '../stores/user'
import { setStore } from '../utils/storage'
const route = useRoute()
const { getShopInfo, getUserView } = useUserStore()
getShopInfo()
const cookieData = cookie.getShop()
useHead({
  link: [
    { rel: 'icon', type: 'image/x-icon', href: cookieData.shopIcon },
    { rel: 'stylesheet', type: 'text/css', href: '//live-cdn.baijiayun.com/www-video-jssdk/dep/videojs/0.0.6/dist/videojs.css' }
  ],
  script: [
    { src: '//lib.baomitu.com/jquery/3.5.1/jquery.min.js' },
    { src: '//live-cdn.baijiayun.com/bplayer/latest/bplayer.js' },
    { src: '//live-cdn.baijiayun.com/bplayer/latest/dep/ffplayer.js' },
    { src: '//player.polyv.net/script/player.js' }
  ]
})
const ctrl = reactive({
  show: false,
  id: ''
})
onMounted(() => {
  getUserView()
  setStore('bjyDefinition', '720p')
  document.addEventListener('click', (e) => {
    if (e && e.path) {
      if (e.path[2] && e.path[2].dataset && e.path[2].dataset.id) {
        ctrl.id = e.path[2].dataset.id
        ctrl.show = true
      } else if (e.path[1] && e.path[1].dataset && e.path[1].dataset.id) {
        ctrl.id = e.path[1].dataset.id
        ctrl.show = true
      }
    }
  })
})
</script>
<style>
@import url('@/assets/font/iconfont.css');
</style>
<style lang="scss" scoped>
#app {
  position: relative;
  background-color: #f6f8fb;
}
.app-main {
  height: 100vh;
  background-color: #f6f8fb;
  overflow: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.app_container{
  padding-bottom: 0 !important;
}
</style>
